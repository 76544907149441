import React from 'react';
import { graphql } from 'gatsby';

// Hooks Querys & Context
import { QueryFragments } from '@querys/queryFragments'; // eslint-disable-line

import Layout from '@components/layout';
import ModuleZone from '~moduleZone';
import ExploreMenu from '@components/fixed/exploreMenu';
import { PageMeta } from '@components/pageMeta';
import PageBanner from '@components/pageModules/modules/pageBanner';
import { useJobListingQuery } from '../GraphQl/useJobListingQuery';
import { SanityImage } from '@components/sanity/sanityImage';
import { Link } from 'gatsby';

const CareersPage = ({ data: { page } }) => {
  const { seo, banner, modules } = page || {};
  const { nodes } = useJobListingQuery();

  return (
    <Layout>
      <PageMeta {...seo} />
      <PageBanner {...banner} />
      <div className='relative'>{modules && <ModuleZone {...modules} />}</div>
      {nodes.length !== 0 && (
        <div className='pt-space-m px-gutter'>
          <h1 className='blockH3 text-blue mb-10 md:mb-24'>
            Current Vacancies
          </h1>
          <div className=' grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3  gap-x-8 gap-y-12 lg:gap-y-16 md:gap-y-24 '>
            {nodes?.map((node) => (
              <Link to={`${node?.slug?.current}`} className='group'>
                <h1 className='blockH7 text-blue mb-2 lg:mb-5'>
                  {node?.location}
                </h1>
                <h1 className='blockH4 text-blue mb-6 lg:mb-10'>
                  {node?.title}
                </h1>
                <div className='aspect-w-5 aspect-h-4 relative overflow-hidden'>
                  <SanityImage
                    image={node?.listingImage}
                    className='w-ful full absolute top-0 left-0 object-cover group-hover:scale-125 transition-all duration-700 ease-in-out'
                  />
                </div>
              </Link>
            ))}
          </div>
        </div>
      )}
      <ExploreMenu />
    </Layout>
  );
};

export default CareersPage;

export const pageQuery = graphql`
  query careersPageQuery {
    page: sanityCareersHome {
      seo {
        ...SEO
      }
      title
      banner {
        title
        subtitle
        bgImage {
          ...ImageWithPreview
        }
        linearGradient
      }
      modules {
        ...CareersHomeModules
      }
    }
  }
`;
