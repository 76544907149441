import React from 'react';
import { SanityImage } from '@components/sanity/sanityImage';
import cx from 'classnames';
import StandardButton from '../../UI/standardButton';

const PageBanner = ({ title, subtitle, bgImage, linearGradient }) => {
  return (
    <div id='top'>
      <div className='h-[50vh] md:h-screen w-full relative flex items-center justify-start text-white '>
        <SanityImage
          image={bgImage}
          className='absolute z-0 w-full h-full top-0 left-0 object-cover'
        />
        <div
          className={cx(
            'absolute top-0 left-0 w-full h-full bg-black bg-opacity-40',
            {
              'md:bg-gradient-to-r from-black  to-transparent': linearGradient,
            },
          )}
        />
        <div className='hidden h-[75vh] mt-auto md:flex relative z-10 px-gutter '>
          <div className='  self-center  relative h-auto  '>
            <h1 className='blockH3 '>{title}</h1>
            <div className='sm:max-w-[80vw] lg:max-w-[50vw]'>
              <h2 className=' blockH5 pt-8 pb-6   '>{subtitle}</h2>
            </div>

            <StandardButton buttonName='Read more' />
          </div>
        </div>
      </div>
      <div className=' md:hidden  z-10 pt-12 px-gutter w-full text-blue bg-green-tint h-[50vh]'>
        <h1 className='blockH3 '>{title}</h1>
        <h2 className=' blockH5 pt-8 pb-6  '>{subtitle}</h2>
        <StandardButton buttonName='Read more' />
      </div>
    </div>
  );
};

export default PageBanner;
